@import 'src/assets/styles/mixins';

.dashboard {
  background: top / contain no-repeat url('../../assets/images/Rectangle\ 3.png');

  &Inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
  }

  .searchWrap {
    margin: auto 0;
    width: 100%;

    .homeInputWrapper {
      margin-top: 32px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 12px;
      padding: 8px 8px 8px 24px;
      display: flex;
      justify-content: space-between;
      position: relative;

      &::after {
        content: attr(checks);
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #909090;
      }

      &.false {
        &::after {
          display: none;
        }
      }

      &.true {
        &::after {
          display: block;
        }
      }

      .phoneInput,
      .upiInput {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--white);
        background-color: transparent;
        outline: unset;
      }

      .searchBtn {
        width: 144px;
        height: 48px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  @include mq(lg-down) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.sorryModal {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>p {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--white);
    margin-bottom: 8px;
  }

  &>span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #919191;
  }

  &>.okBtn {
    margin-top: 48px;
    color: var(--white);
    height: 48px;
    width: 160px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

}