@import 'src/assets/styles/mixins';

.chat {
  //background: top / contain no-repeat url('../../assets/images/Rectangle\ 3.png');

  &Inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
  }

  .chatWrap {
    margin-top: 48px;
    display: inherit;
    flex-direction: inherit;
    align-items: center;

    &>* {
      width: 100%;
    }

    .searchBtn {
      width: 144px;
      height: 48px;
      margin-bottom: 48px;
    }

    .chatList {
      display: inherit;
      flex-direction: inherit;
      align-items: flex-start;
      margin-top: 48px;
      margin-bottom: 24px;
      gap: 24px;

      &__item {
        width: 100%;
        display: inherit;
        flex-direction: inherit;
        gap: 8px;

        &:not(.reply) {
          padding-bottom: 24px;
          border-bottom: 2px solid #333;
        }

        &.reply {
          background: #202020;
          border-radius: 12px;
          padding: 24px;
          margin-top: 16px;
        }

        .heading {
          display: inherit;
          align-items: center;

          span {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #CECECE;
            margin-right: 8px;

            &.date {
              font-weight: 500;
              color: #909090;
            }
          }

          svg {
            margin-right: 4px;
          }
        }

        .body {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #FFFFFF;
        }
      }

      .noMessages {
        color: #909090;
        align-self: center;
        font-size: 14px;
        margin-top: 36px;
      }
    }

    .sendMessage {
      display: flex;
      align-items: flex-end;
      gap: 24px;
      padding: 24px;
      margin-top: 48px;
      background: #202020;
      border-radius: 12px;

      &>label {
        width: 100%;
      }

      &>* {
        margin: 0;
      }
    }
  }
}