.root {
    display: flex;
    width: 100%;
    align-items: center;

    span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--white);
        margin: 0 4px 0 12px;
    }

    input {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--white);
        background-color: transparent;
        outline: unset;
    }
}