@import 'reset';
@import 'vars';
@import 'helpers';
@import 'mixins';
@import '../fonts/Inter/stylesheet';
@import '../fonts/Montserrat/stylesheet';
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext');

body {
  font-family: var(--font);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--primary);
  background-color: var(--dark);
  @include scrollbar(15px, var(--primary), var(--light), 5px, 2px);
}

a {
  color: var(--info);
}

.root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  //background-position: top;

}

.page-wrap {
  flex: 1;
  background-color: transparent;
  padding-top: 96px;
  display: flex;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: var(--container);
  width: 100%;
}

.card-wrap {
  padding: 24px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

h1,
.h1 {
  font-size: 32px;
  line-height: 41px;
}

h2,
.h2,
h3,
.h3 {
  font-size: 24px;
  line-height: 24px;
  color: var(--secondary);
}

h3,
.h3 {
  font-size: 18px;
}

.backlink {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--info);

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-right: 8px;
  }
}

// Table
.table {
  tr {
    border-bottom: 1px solid var(--light);
  }

  th {
    vertical-align: middle;
    padding: 8px 16px;
    height: 50px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: var(--info);
  }

  td {
    vertical-align: middle;
    padding: 8px 16px;
    height: 60px;
  }
}

.table-inner {
  display: flex;
  gap: 16px;

  &--right {
    justify-content: right;
  }

  &--center {
    justify-content: center;
  }
}

// Helper classes
.flex {
  display: flex;
  flex-wrap: wrap;

  &--column {
    flex-direction: column;
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.gap-25 {
  gap: 25px;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.full-width {
  width: 100%;
}

.good {
  color: var(--success);
}

.alert {
  color: var(--error);
}

.warning {
  color: var(--warning);
}

.bold {
  font-weight: 700;
}

.good-hover {
  transition: opacity ease 0.3s;

  @media (hover) {

    &:hover,
    &:focus-visible {
      opacity: 0.7;
    }
  }
}

.card-hover {
  cursor: pointer;
  transition: margin-top ease 0.3s, margin-bottom ease 0.3s;

  @media (hover) {

    &:hover,
    &:focus-visible {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
}

.no-select {
  user-select: none;
}

// Animations
.pulse {
  @media (hover) {

    &:hover,
    &:focus-visible {
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  0% {
    opacity: 1;
  }
}

// React Toastify
.Toastify__toast {
  font-family: inherit;

  &--error {
    background-color: var(--error);
  }

  &--success {
    background-color: var(--success);
  }
}

.page-heading {
  margin-top: 64px;
  text-align: center;

  &.main {
    margin-top: 128px;
  }

  &>span {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #909090;
    margin-bottom: 8px;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: var(--white);
    text-align: center;
    margin-bottom: 16px;

    span {
      background: linear-gradient(265.14deg, #FF42AF 0%, #F3702A 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #CECECE;
  }
}

input {
  outline: unset !important;
  background-color: transparent !important;
  color: var(--white) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #303030 inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--white) !important;
}

.fraud-text {
  background: linear-gradient(268.5deg, #FF42AF 0%, #F3702A 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.tabs {
  display: flex;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
  }

  &::before {
    width: 100%;
    background: #333333;
  }

  &::after {
    width: 50%;
    background: linear-gradient(269.98deg, #FF42AF 0%, #F3702A 100%);
    transition: transform 250ms;
  }

  &.false::after {
    transform: translateX(0);
  }

  &.true::after {
    transform: translateX(100%);
  }

  button {
    width: 50%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.3;
    padding: 16px;

    &.active,
    &:hover {
      opacity: 1;
    }
  }
}