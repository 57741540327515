.root {
  z-index: 1;
  background-color: transparent;
  //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #333333;

  &.notHome {
    background-color: var(--dark);
  }

  .container {
    display: flex;
    justify-content: center;
    min-height: 96px;
    padding: 0 64px;
  }

  .logo {
    //max-width: 110px;
  }

  .icon {
    max-width: 24px;
    max-height: 24px;
    cursor: pointer;
    transition: transform 150ms;
  }

  .nav {
    margin: 0 auto;
    display: flex;
  }

  .navLink {
    display: flex;
    align-items: center;
    opacity: 0.3;
    width: 102px;
    position: relative;
    justify-content: center;
    color: var(--white);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    &:hover,
    &:focus-visible,
    &:global(.active) {
      opacity: 1;
    }

    &:global(.active) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(269.98deg, #ff42af 0%, #f3702a 100%);
      }
    }
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 16px;

  & > div {
    margin-right: 4px;
    text-align: right;

    .userName {
      display: block;
      //margin-bottom: 1px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--white);
    }

    .userChecks {
      display: block;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      &.false {
        color: #909090;
      }

      &.true {
        background: linear-gradient(268.5deg, #ff42af 0%, #f3702a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .actionBtn {
    width: 120px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    &.signIn {
    }

    &.signUp {
    }
  }

  .dropdown {
    position: relative;

    &Opened {
      .icon {
        transform: rotate(180deg);
      }
    }

    &Content {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: calc(100% + 20px);
      right: -20px;
      min-width: 220px;
      border-radius: 16px;
      background: #202020;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
      padding: 24px;
    }

    &Button {
      padding: 0;
      color: #fff;
      background-clip: unset;
      background: transparent;
      -webkit-text-fill-color: unset;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      justify-content: flex-start;

      &:hover {
        background-color: transparent;
      }

      svg {
        margin-right: 8px;
      }

      &.delete {
        padding-top: 24px;
        color: #fb3256;
      }

      &:not(.delete) {
        padding-bottom: 24px;
      }
    }

    &Separator {
      width: 100%;
      height: 2px;
      background: #333;
    }
  }
}
