.button-mui {
  padding: 6px 16px;
  min-width: 60px;
  height: 40px;
  font-family: inherit;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: normal;
  border-radius: 8px;
  box-shadow: none;
  text-transform: unset;

  &:hover {
    opacity: 1;
  }

  &:disabled {}

  // variant='contained'
  &--contained {
    color: var(--white);
    background: linear-gradient(263.66deg, #FF42AF 0%, #F3702A 100%);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      background-color: darken(#3796f6, 10%);
    }

    &-primary {
      background-color: var(--primary);

      &:hover {
        background-color: darken(#204569, 10%);
      }
    }

    &-secondary {
      background-color: var(--error);

      &:hover {
        background-color: darken(#e44b4b, 10%);
      }
    }
  }

  // variant='outlined'
  &--outlined {
    color: var(--white);
    border-color: #979797;

    &:hover {
      //background-color: lighten(#3796f6, 35%);
      border-color: var(--white);
    }

    &-primary {
      color: var(--primary);
      border-color: var(--primary);

      &:hover {
        background-color: lighten(#204569, 60%);
      }
    }

    &-secondary {
      color: var(--error);
      border-color: var(--error);

      &:hover {
        background-color: lighten(#e44b4b, 35%);
      }
    }
  }

  // variant='text'
  &--text {
    color: var(--info);
    font-weight: 700;

    &:hover {
      background-color: lighten(#3796f6, 35%);
    }

    .MuiTouchRipple-child {
      background: linear-gradient(263.66deg, #FF42AF 0%, #F3702A 100%);
    }

    &-primary {
      color: var(--primary);

      &:hover {
        background-color: lighten(#204569, 60%);
      }
    }

    &-secondary {
      color: var(--error);

      &:hover {
        background-color: lighten(#e44b4b, 35%);
      }
    }
  }

  // size='small'
  &--small {
    min-width: 105px;
    height: 35px;
    font-size: 12px;
    line-height: 16px;
  }

  // size='large'
  &--large {
    min-width: 168px;
    height: 56px;
    font-size: 18px;
    line-height: 20px;
  }

  // startIcon & endIcon max size
  &__start-icon svg,
  &__end-icon svg {
    max-width: 20px;
    max-height: 20px;
  }
}