.root {
  height: 96px;
  display: flex;
  align-items: center;
  padding: 0 64px;
  background: #202020;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--white);
    opacity: 0.5;

  }

}