@import 'src/assets/styles/mixins';

.chat {
  //background: top / contain no-repeat url('../../assets/images/Rectangle\ 3.png');

  &Inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
  }

  .resultsWrap {
    margin: 48px 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__table {
      padding: 48px;
      display: inherit;
      flex-direction: inherit;
      align-items: inherit;
      border-radius: 12px;
      width: 100%;

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
      }

      &.true {
        background: rgba(73, 193, 136, 0.1);

        p {
          color: #49C188;
        }
      }

      &.false {
        background: rgba(251, 50, 86, 0.1);
        padding-top: 32px;

        p {
          color: #FB3256;
        }
      }
    }

    .separator {
      width: 100%;
      height: 2px;
      background: #333333;
      margin: 48px 0;
    }

    .infoText {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #CECECE;
      margin-bottom: 24px;
    }

    .searchBtn {
      width: 144px;
      height: 48px;
      margin-bottom: 48px;
    }
  }

}

.fraudLoader {
  display: flex;
  position: relative;

  svg {
    position: absolute;
    width: inherit;
    height: inherit;

    &:first-child {
      path {

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          display: none;
        }
      }
    }

    &:last-child {
      padding: 10px 15px 10px 0;

      path {

        &:nth-child(1),
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 1.5s linear infinite;
  -moz-animation: rotating 1.5s linear infinite;
  -ms-animation: rotating 1.5s linear infinite;
  -o-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
}