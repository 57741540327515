:root {
  --font: 'Inter', sans-serif;
  --subfont: 'Montserrat', sans-serif;

  --primary: #204569;
  --secondary: #0a2540;
  --error: #e44b4b;
  --warning: #ffae00;
  --info: #3796f6;
  --success: #2cc197;
  --light: #ebf3fa;
  --dark: #1A1A1A;
  ;

  --white: #ffffff;
  --black: #000000;
  --gray: #ababab;

  --container: 752px;
  --transition-duration: 0.3s;
}