.authModal {
    display: flex;
    flex-direction: column;
    align-items: center;

    .modalTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: var(--white);
        margin-bottom: 40px;
    }
}