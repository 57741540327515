.tabs {
  &__menu {
    margin-bottom: 20px;
  }

  &__btn {
    position: relative;
    padding: 10px 15px;
    font-weight: 600;
    color: var(--primary);

    &:after {
      content: '';
      width: 0;
      transition: 0.3s;
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    &--active,
    &:hover {
      color: var(--info);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: var(--info);
      }
    }
  }

  &__item {
  }
}
