.pagination {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }

  &__item {
    min-width: 24px;
    height: 24px;
    border-radius: 2px;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: rgba(132, 148, 185, 0.1);
    }
  }

  &__link {
    display: grid;
    place-items: center;
    padding: 4px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 14px;
    line-height: 14px;
    color: var(--info);
    border-radius: 2px;

    &--active {
      color: var(--white);
      background-color: var(--info);

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__ellipsis {
    display: flex;
  }

  &__ellipsis-link {
    height: 24px;
    width: 24px;
    text-decoration: none;
    text-align: center;
    color: var(--info);
  }

  &__prev-item,
  &__next-item {
    height: 24px;
    width: 24px;
    border-radius: 2px;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: rgba(132, 148, 185, 0.1);
    }

    &.disabled {
      opacity: 0.5;

      a {
        cursor: not-allowed;
      }
    }
  }

  &__prev-link,
  &__next-link {
    display: flex;
  }

  &__icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    fill: var(--info);
  }
}
