@import 'src/assets/styles/mixins';

.fraud_field {
    &_label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 24px;
        position: relative;

        &__title {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--white);
            margin-bottom: 8px;
        }

        &__content {
            display: flex;
            background: rgba(255, 255, 255, 0.06);
            border-radius: 12px;
            height: 48px;
            width: 100%;
            padding: 0 16px;
            align-items: center;
            cursor: text;

            input {
                width: 100%;
            }
        }
    }

    &_password {
        display: flex;
        align-items: center;
        width: 100%;

        input {
            width: calc(100% - 24px);
        }

        .recoverBtn,
        svg {
            cursor: pointer;
        }

        .recoverBtn {
            position: absolute;
            top: 0;
            right: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }
    }
}

.submitBtn {
    width: max-content;
    min-width: 160px;
    height: 48px;
    align-self: center;
    margin-top: 24px;
}

.verifyInput {
    margin-bottom: 24px;
    width: 100% !important;

    &>div {
        display: flex;
        gap: 12px;
        justify-content: center;

        input {
            width: 72px !important;
            height: 72px !important;
            background: rgba(255, 255, 255, 0.06) !important;
            border-radius: 8px !important;
            color: var(--white) !important;
            font-weight: 600 !important;
            font-size: 24px !important;
            line-height: 32px !important;
            font-family: var(--font) !important;
            border: none !important;
            caret-color: var(--white) !important;
        }
    }
}

.modalWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .desc {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #919191;
        margin-top: -32px;
        margin-bottom: 48px;

        span {
            color: var(--white);
        }

        &+button {
            margin-top: 0;
        }
    }

    .info {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #CECECE;
        text-align: center;
        margin-top: 48px;
    }

    .buttons {
        display: flex;
        gap: 20px;
        justify-content: center;

        .actionBtn {
            height: 48px;
            width: 140px;
            text-transform: unset;
            font-weight: 700 !important;
            font-size: 16px !important;
        }
    }
}